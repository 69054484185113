import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAt-iWWzd3TnqwZUrQQJjd8YYjZmLk2jxA',
  authDomain: 'mybook-idiomas.firebaseapp.com',
  projectId: 'mybook-idiomas',
  storageBucket: 'mybook-idiomas.appspot.com',
  messagingSenderId: '539983114050',
  appId: '1:539983114050:web:44a1814f8a5076fdfb22db',
  measurementId: 'G-1Q6HKH583J',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
